import Axios from 'axios';
import { getTenant } from '@nio-fe/pcp-sdk-core/vue';

export const getConfig = () => {
  return (window as any).config || {};
};
export const getEnv = () => {
  const url = window.location.href;

  if (url.indexOf('dev') > -1) {
    return 'dev';
  } else if (url.indexOf('test') > -1) {
    return 'test';
  } else if (url.indexOf('stg') > -1) {
    return 'stg';
  } else if (url.indexOf('127.0.0.1') > -1 || url.indexOf('localhost') > -1) {
    return 'dev';
  } else {
    return 'prod';
  }
};

export const postRequest = async (
  url: string,
  data: any = {},
  headers: any = {},
) => {
  const mergedParams = { tenant_id: getTenant() };
  return await Axios.post(url, data, {
    headers,
    params: mergedParams,
  });
};
export const getRequest = async (
  url: string,
  params: any = {},
  headers: any = {},
) => {
  const mergedParams = { ...params, tenant_id: getTenant() };
  return await Axios.get(url, {
    headers,
    params: mergedParams,
  });
};
