//防抖
export function debounce(func, delay) {
  let timer = null;
  return function () {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      func.call(this, ...arguments);
    }, delay);
  };
}

//获取上传的环境和clientSecret
export function filtersEnvSecret() {
  let url = window.location.href;
  let client_secret: string, env: string;
  let bucketInfo = {
    bucket_key: 'standard_cn_pub_3c9f54',
    client_id: 'phb_cn_5aa53c',
  } as any;
  if (url.indexOf('dev') > -1) {
    client_secret = 'dev_788a34f1e2c9bf8ed7cc1a93c4950f5b';
    env = 'dev';
  } else if (url.indexOf('test') > -1) {
    client_secret = 'test_eb15ed79af215bac01be6f8477b1b78f';
    env = 'test';
  } else if (url.indexOf('stg') > -1) {
    client_secret = 'stg_d80665ca76dfd8b102cf7490f1a8d307';
    env = 'stg';
  } else if (url.indexOf('127.0.0.1') > -1) {
    client_secret = 'dev_788a34f1e2c9bf8ed7cc1a93c4950f5b';
    env = 'dev';
  } else {
    client_secret = 'prod_b82b366bf0a91c5f5b221f74862504e8';
    env = 'prod';
  }
  bucketInfo.env = env;
  bucketInfo.client_secret = client_secret;
  return bucketInfo;
}

//获取不同环境的飞书APPID
export function feishuAppID() {
  let url = window.location.href;
  let appID: string;
  if (url.indexOf('dev') > -1) {
    appID = 'cli_a42d68e90faa100e';
  } else if (url.indexOf('test') > -1) {
    appID = 'cli_a42d68e90faa100e';
  } else if (url.indexOf('stg') > -1) {
    appID = 'cli_a42d6c0c9779100b';
  } else if (url.indexOf('127.0.0.1') > -1 || url.indexOf('localhost') > -1) {
    appID = 'cli_a42d68e90faa100e';
  } else {
    appID = 'cli_a233b4fd1ef9500b';
  }
  return appID;
}

export const wxWorkIdMap = {
  stg: {
    phb: {
      agentId: '1000168',
      corpId: 'wx8c7c17186ca8a114',
    },
    'alps-phb': {
      agentId: '1000018',
      corpId: 'ww7d974e2f2592f081',
    },
  },
  prod: {
    phb: {
      agentId: '1000167',
      corpId: 'wx8c7c17186ca8a114',
    },
    'alps-phb': {
      agentId: '1000018',
      corpId: 'ww7d974e2f2592f081',
    },
  },
};

//时间戳转换成年月日
export function formatDate(timestamp: any) {
  if (!timestamp) {
    return '';
  }
  let date = new Date(timestamp * 1000);
  let year = date.getFullYear();
  let month = formatNumber(date.getMonth() + 1);
  let day = formatNumber(date.getDate());
  return `${year}-${month}-${day}`;
}

//时间戳转换年月日并判断
export function formatDateTamp(timestamp: any) {
  if (!timestamp) {
    return '';
  }
  if (timestamp === -1) {
    return '<span>长期有效</span>';
  }
  let newDate = Math.floor(Date.now() / 1000);
  let date = new Date(timestamp * 1000);
  let year = date.getFullYear();
  let month = formatNumber(date.getMonth() + 1);
  let day = formatNumber(date.getDate());
  if (
    formatDate(newDate) === formatDate(timestamp) ||
    formatDate(newDate) > formatDate(timestamp)
  ) {
    return `<span style="color:#F45552">${year}-${month}-${day} 已失效</span>`;
  }
  return `<span>${year}-${month}-${day}` + ` 前有效</span>`;
}

//时间戳转换成年月日时分秒
export function formatTimestamp(timestamp: any) {
  if (!timestamp) {
    return '';
  }
  let date = new Date(timestamp * 1000);
  let year = date.getFullYear();
  let month = this.formatNumber(date.getMonth() + 1);
  let day = this.formatNumber(date.getDate());
  let hour = this.formatNumber(date.getHours());
  let minute = this.formatNumber(date.getMinutes());
  let second = this.formatNumber(date.getSeconds());
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}

//时间戳转换成英文年月日
export function formatDateEu(timestamp: any) {
  if (!timestamp) {
    return '';
  }
  let date = new Date(timestamp * 1000);
  let options: Object = { year: 'numeric', month: 'long', day: 'numeric' };
  let englishTime = date.toLocaleString('en-US', options);
  return englishTime;
}

//时间戳转换成英文年月日时分秒
export function formatTimestampEu(timestamp: any) {
  if (!timestamp) {
    return '';
  }
  let date = new Date(timestamp * 1000);
  let options: Object = {
    timeZone: 'UTC',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };
  let englishTime = date.toLocaleString('en-US', options);
  return englishTime;
}

export function formatNumber(num: any) {
  return num < 10 ? '0' + num : num;
}

export function locales() {
  return localStorage.getItem('lang') || 'cn';
}

//高亮显示
export function highlight(str: any) {
  const keyword: any = localStorage.getItem('searchResult')
    ? localStorage.getItem('searchResult')
    : '';
  if (!keyword) {
    return str;
  }
  const searchArray = keyword
    ? keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').split(' ')
    : '';
  const reg = new RegExp(searchArray.join('|'), 'gi');
  return str.replace(reg, (item: any) => {
    return `<span style="color:var(--brand-primary-color)">${item}</span>`;
  });
}

//原词高亮
export function hightLight(str: any) {
  const keyword: any = localStorage.getItem('primitive')
    ? localStorage.getItem('primitive')
    : '';
  if (!keyword) {
    return str;
  }
  const searchArray = keyword
    ? keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').split(' ')
    : '';
  const reg = new RegExp(searchArray.join('|'), 'gi');
  const highlightedText = str.replace(reg, (item: any) => {
    return `${item}`;
  });
  const doubleHighlightedText = highlightedText.replace(
    /<span style="color:var(--brand-primary-color)">([^<]+)<\/span>/gi,
    (item) => {
      return `<span style="color:var(--brand-primary-color)">${item}</span>`;
    },
  );
  return doubleHighlightedText;
}

export function detectRegionUrl() {
  const language = localStorage.getItem('lang') === 'eu' ? 'en-GB' : 'zh-CN';
  return language;
}

export function handleURLObj(url) {
  // 分割 URL，获取参数部分
  const paramsString = url.split('?')[1];

  // 分割参数部分，获取键值对
  const keyValuePairs = paramsString.split('&');

  // 遍历键值对，找到 id 参数并提取值
  let content_id;
  keyValuePairs.forEach((pair) => {
    const [key, value] = pair.split('=');
    if (key === 'id') {
      content_id = value;
    }
  });
  return content_id;
}

// 存值函数
// 接收三个参数：键、值、有效天数
export const setCanExpireLocal = (key, value, expire) => {
  // 判断传入的有效期是否为数值或有效
  // isNaN是js的内置函数，用于判断一个值是否为NaN（非数值），
  // 非数值返回true，数值返回false
  // 还可以限制一下有效期为整数，这里就不做了
  if (isNaN(expire) || expire < 1) {
    throw new Error('有效期应为一个有效数值');
  }
  // 86_400_000一天时间的毫秒数，_是数值分隔符
  let time = expire * 60 * 60 * 1000;
  let obj = {
    data: value, //存储值
    time: Date.now(), //存值时间戳
    expire: time, //过期时间
  };
  // 注意，localStorage不能直接存储对象类型，sessionStorage也一样
  // 需要先用JSON.stringify()将其转换成字符串，取值时再通过JSON.parse()转换回来
  localStorage.setItem(key, JSON.stringify(obj));
};

// 取值函数
// 接收一个参数，存值的键
export const getCanExpireLocal = (key) => {
  let val: any = localStorage.getItem(key);
  // 如果没有值就直接返回null
  if (!val) return val;
  // 存的时候转换成了字符串，现在转回来
  val = JSON.parse(val);
  // 存值时间戳 +  有效时间 = 过期时间戳
  // 如果当前时间戳大于过期时间戳说明过期了，删除值并返回提示
  if (Date.now() > val.time + val.expire) {
    localStorage.removeItem(key);
    return null;
  }
  return val.data;
};
